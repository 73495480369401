import HttpService from './http-service.js';
const httpService = new HttpService();

export default ({
    async getList(filter)
    {
        const response = await httpService.get(`FeeProfile`, filter);
        const result = {
            pageNumber: 0,
            perPage: 0,
            totalPage: 0,
            totalRow: 0,
            data: Array()
        };

        if (!response.status)
        {
            response.data = result;
            return response;
        }

        result.pageNumber = response.data.pageNumber;
        result.perPage = response.data.perPage;
        result.totalPage = response.data.totalPage;
        result.totalRow = response.data.totalRow;
        for(let i = 0; i < response.data.data.length; i++)
        {
            result.data.push(this._toObject(response.data.data[i]));
        }

        response.data = result;
        return response;
    },

    async getObject(id) {
        const response = await httpService.get(`FeeProfile/${id}`);
        if (!response.status) {
            response.data = this.default();
            return response;
        }
        response.data = this._toObject(response.data);
        return response;
    },

    async create(model) {
        const response = await httpService.post('FeeProfile', model);
        if (!response.status) {
            response.data = this.default();
            return response;
        }
        response.data = this._toObject(response.data);
        return response;
    },

    async update(model, id) {
        const response = await httpService.update('FeeProfile', id, model);
        if (!response.status) {
            response.data = false;
        }
        return response;
    },

    async delete(id) {
        const response = await httpService.delete('FeeProfile', id);
        if (!response.status) {
            response.data = false;
        }
        return response;
    },

    async deleteListItem(id) {
        const response = await httpService.delete('FeeProfileList', id);
        if (!response.status) {
            response.data = false;
        }
        return response;
    },

    default() {
        return {
			id: 0,
			nama: "",
            vendorId: 0,
            vendorName: "",
			timeRangeId: 0,
			timeRangeNama: "",
			locationId: 0,
			locationCode: "",
			airlineId: 0,
			airlineName: "",
			currencyId: 0,
			currencyCode: "",
			unitId: 0,
			unitCode: "",
            startDay: 0,
			feeProfileList: [
				{
					id: 0,
					feeProfileId: 0,
					feeId: 0,
					feeName: ""
				}
			]
        };
    },

    _toObject(jsonData) {
        const result = this.default();
        result.id = jsonData.id;
        result.nama = jsonData.nama;
        result.vendorId = jsonData.vendorId;
        result.vendorName = jsonData.vendorName;
        result.timeRangeId = jsonData.timeRangeId;
        result.timeRangeNama = jsonData.timeRangeNama;
        result.locationId = jsonData.locationId;
        result.locationCode = jsonData.locationCode;
        result.airlineId = jsonData.airlineId;
        result.airlineName = jsonData.airlineName;
        result.currencyId = jsonData.currencyId;
        result.currencyCode = jsonData.currencyCode;
        result.unitId = jsonData.unitId;
        result.unitCode = jsonData.unitCode;
        result.startDay = jsonData.startDay;
        result.feeProfileList = [];
        jsonData.feeProfileList?.forEach(item => {
            result.feeProfileList.push(
                {
                    id: item.id,
                    feeProfileId: item.feeProfileId,
                    feeId: item.feeId,
                    feeName: item.feeName,
                }
            )
        });
        return result;
    }
});