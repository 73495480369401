<template>
	<bs-popup id="mapping-aircraft-popup" @on-popup-closed="onPopupClosed" :auto-show="true" ref="form" :is-large="true && !id">
		<template v-slot:title>Fee</template>
		<template v-slot:content>
			<bs-error-message :errors="errorMessages"/>
			<div class="mb-3" v-if="!id">
				<label for="nama" class="form-label">Fee Profile</label>
				<fee-profile-combo v-model="model.feeProfileId" :textDefault="model.feeProfileNama" @setSelectedProfile="setSelectedProfile"/>
			</div>
			<div class="mb-3" v-if="id">
				<label for="nama" class="form-label">Fee Profile</label>
				<bs-plain-text v-model="model.feeProfileNama" class="fw-bold" />
			</div>
			<div class="mb-3" v-if="model.feeProfileId && !id">
				<label for="nama" class="form-label">Bulan</label>
				<month-combo v-model="month" :textDefault="monthNama"/>
			</div>
			<div class="mb-3 text-end" v-if="!id">
				<div>
					<button class="btn btn-sm btn-primary" @click="generateFeeProfile">Generate Fee Profile</button>
				</div>
			</div>
			<div v-if="inputFees.length > 0" class="mb-3">
				<div v-for="(item, index) in feeProfileList" :key="'outside-' + index">
					<div class="mb-3">
						<label for="nama" class="form-label">Fee Name</label>
						<bs-plain-text v-model="feeList[item.feeId-1].name" class="fw-bold"/>
					</div>
					<div class="mb-3 row" v-for="(fees, index) in inputFees.filter((fee) => fee.feeName === feeList[item.feeId-1].name)" :key="'inside-' + index">
						<div :class="{ 'col-md-3':!id, 'col-md-12': id}">
							<label for="code" class="form-label">Remark</label>
							<bs-textbox v-model="fees.remarks"/>
						</div>
						<div :class="{ 'col-md-3':!id, 'col-md-12': id}">
							<label for="nama" class="form-label">Quantity</label>
							<bs-textbox type="number" v-model="fees.quantity"/>
						</div>
						<div :class="{ 'col-md-3':!id, 'col-md-12': id}">
							<label for="nama" class="form-label">Valid Start</label>
							<bs-date-picker v-model="fees.validStart" :disabled="timeRangeName === 'Monthly'"/>
						</div>
						<div :class="{ 'col-md-3':!id, 'col-md-12': id}">
							<label for="nama" class="form-label">Valid End</label>
							<bs-date-picker v-model="fees.validEnd" :disabled="timeRangeName === 'Monthly'"/>
						</div>
						<div :class="{ 'col-md-3':!id, 'col-md-12': id}">
							<label for="nama" class="form-label">Status: <span class="fw-bold">{{ statusList[fees.status] }}</span></label>
						</div>
					</div>
				</div>
			</div>
			
		</template>
		<template v-slot:footer>
			<button class="btn btn-sm btn-primary" @click="saveData"><i class="bi bi-check-circle"></i> Save</button>
			<button class="btn btn-sm btn-secondary" @click="close"><i class="bi bi-x-circle"></i> Cancel</button>
		</template>
	</bs-popup>
</template>

<script>
import FeeService from "@/services/fee-service.js";
import FeeProfileCombo from "@/components/FeeProfileCombo.vue";
import MonthCombo from "@/components/MonthCombo.vue";
import { toRaw } from "vue";
import {getCurrentYear, getFirstGivenIndexDate, getMonthDateRange} from "@/plugins/helper";
import BsDatePicker from "@/plugins/components/BsDatePicker.vue";
import Moment from "moment";

export default {
	components: {
		BsDatePicker,
		FeeProfileCombo,
		MonthCombo
	},
	data: function () {
		return {
			id: 0,
			model: FeeService.default(),
			errorMessages: null,
			feeList: [
				{
					id: 1,
					name: "Government Fee",
				},
				{
					id: 2,
					name: "Throughput Fee",
				},
				{
					id: 3,
					name: "Flowage Fee",
				},
			],
			statusList: [
				"Not OK",
				"OK",
				"OK-Revised"
			],
			startDay: 0,
			timeRangeNama: '',
			inputFees: [],
			month: 0,
			monthNama: '',
			feeProfileList: [],
		}
	},
	mounted() {
		let id = this.$route.params.id;
		if (id !== undefined && id !== null && id !== 0) {
			this.id = id;
			this.loadData();
		}
	},
	methods: {
		async loadData() {
			if (this.id === undefined || this.id === null || this.id === 0) {
				return;
			}

			const result = await FeeService.getObject(this.id);
			console.log(result);
			if (!result.status) {
				this.errorMessages = result.errorMessages;
			} else {
				console.log(result);
				this.errorMessages = null;
				this.feeProfileList.push(result.data);
				this.addInputFee(result.data.feeProfileListId, result.data.remarks, new Date(result.data.validStart), new Date(result.data.validEnd), result.data.quantity, result.data.status, this.feeList[result.data.feeId-1].name);
			}

			this.model = result.data;
		},
		async onPopupClosed() {
			this.$router.push('/fee');
		},
		async close() {
			this.$refs.form.closePopup();
		},
		async saveData() {
			try {
				this.$store.commit('progressCounterQueueIncrement');
				var data = {
					inputFees: this.inputFees.map((item) => {
						return {
							id: parseInt(this.id)?? 0,
							feeProfileListId: item.feeProfileListId,
							remarks: item.remarks,
							validStart: item.validStart,
							validEnd: item.validEnd,
							quantity: parseFloat(item.quantity),
							status: item.quantity !== null? 1 : 0,
							feeName: item.feeName
						}
					})
				}
				let status = false;
				if (this.id !== undefined && this.id !== null && this.id !== 0) {
					const result = await FeeService.update(data.inputFees[0], this.id);
					status = result.status;
					if (status) {
						this.errorMessages = null;
					}
					else {
						this.errorMessages = result.errorMessages;
					}
				}
				else {
					const result = await FeeService.create(data);
					status = result.status;
					console.log('save status', status);
					if (status) {
						this.errorMessages = null;
						this.id = result.data.id;
					}
					else {
						this.errorMessages = result.errorMessages;
					}
				}

				if (!status) return;

				this.$refs.form.closePopup();
				this.$router.push(`/fee`);
			}
			finally {
				this.$store.commit('progressCounterQueueDecrement');
			}
		},
		setSelectedProfile(startDay, timeRangeNama, feeProfileList){
			this.startDay = startDay;
			this.timeRangeNama = timeRangeNama;
			this.feeProfileList = toRaw(feeProfileList);
			this.inputFees = [];
		},
		generateFeeProfile(){
			const dateRange = getMonthDateRange(getCurrentYear(), this.month);
			if(!this.model.feeProfileId && !this.month){
				alert('Pilih Fee Profile dan Bulan terlebih dahulu.');
				return;
			}
			if(this.timeRangeNama == 'Monthly'){
				this.feeProfileList.forEach(item => {
					this.addInputFee(item.id, '', dateRange.start.toDate(), dateRange.end.toDate(), 0, 0, this.feeList[item.feeId-1].name);
				});
			}else if(this.timeRangeNama == 'Bi-Weekly'){
				if (dateRange.end.day() === 28 || dateRange.end.day() === 29) {
					this.feeProfileList.forEach(item => {
						this.addInputFee(item.id, '', dateRange.start.toDate(), dateRange.start.add(13, 'day').toDate(), 0, 0, this.feeList[item.feeId-1].name);
						this.addInputFee(item.id, '', dateRange.start.add(1, 'day').toDate(), dateRange.end.toDate(), 0, 0, this.feeList[item.feeId-1].name);
					});
				} else {
					this.feeProfileList.forEach(item => {
						this.addInputFee(item.id, '', dateRange.start.toDate(), dateRange.start.add(14, 'day').toDate(), 0, 0, this.feeList[item.feeId-1].name);
						this.addInputFee(item.id, '', dateRange.start.add(1, 'day').toDate(), dateRange.end.toDate(), 0, 0, this.feeList[item.feeId-1].name);
					});
				}
			}else if(this.timeRangeNama == 'Weekly'){
				const firstDay = getFirstGivenIndexDate(getCurrentYear(), this.month, this.startDay || 1);
				this.feeProfileList.forEach(item => {
					const sameStartDay = firstDay.date() === dateRange.start.date();
					let pointerStart = Moment([dateRange.start.year(), dateRange.start.month(), dateRange.start.date()]);
					let pointerEnd = null;
					if(sameStartDay){
						pointerEnd = Moment([dateRange.start.year(), dateRange.start.month(), dateRange.start.date() + 6]);
					}else{
						pointerEnd = Moment([firstDay.year(), firstDay.month(), firstDay.date()-1]);
					}
					while (pointerStart.isSame(dateRange.end,'month')){
						var start = pointerStart.toDate();
						var end = null;
						if(pointerEnd.isSame(dateRange.end, 'month')){
							end = pointerEnd.toDate();
						}else{
							end = dateRange.end.toDate();
						}
						this.addInputFee(item.id, '', start, end, 0, 0, this.feeList[item.feeId-1].name);
						if(pointerEnd.diff(pointerStart, 'days')===6){
							pointerStart.add(7, 'day');
							pointerEnd.add(7, 'day');
						}else{
							pointerStart.set({'year': pointerEnd.year(), 'month': pointerEnd.month(),'date':pointerEnd.date()+1})
							pointerEnd.set({'year': pointerStart.year(), 'month': pointerStart.month(),'date':pointerStart.date()+6})
						}
					}
				})
			}
		},
		addInputFee(id, remarks, validStart, validEnd, quantity, status, feeName){
			console.log('test');
			var val = {
				feeProfileListId: id,
				remarks: remarks,
				validStart: validStart,
				validEnd: validEnd,
				quantity: quantity,
				status: status,
				feeName: feeName
			}
			this.inputFees.push(val);
		}
	}
}
</script>