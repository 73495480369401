import HttpService from './http-service.js';
const httpService = new HttpService();

export default ({
    async getList(filter)
    {
        const response = await httpService.get(`fee`, filter);
        const result = {
            pageNumber: 0,
            perPage: 0,
            totalPage: 0,
            totalRow: 0,
            data: Array()
        };

        if (!response.status)
        {
            response.data = result;
            return response;
        }

        result.pageNumber = response.data.pageNumber;
        result.perPage = response.data.perPage;
        result.totalPage = response.data.totalPage;
        result.totalRow = response.data.totalRow;
        for(let i = 0; i < response.data.data.length; i++)
        {
            result.data.push(this._toObject(response.data.data[i]));
        }

        response.data = result;
        return response;
    },

    async getObject(id) {
        const response = await httpService.get(`fee/${id}`);
        if (!response.status) {
            response.data = this.default();
            return response;
        }
        response.data = this._toObject(response.data);
        return response;
    },

    async create(model) {
        const response = await httpService.post('fee', model);
        if (!response.status) {
            response.data = this.default();
            return response;
        }
        response.data = this._toObject(response.data);
        return response;
    },

    async update(model, id) {
        const response = await httpService.update('fee', id, model);
        if (!response.status) {
            response.data = false;
        }
        return response;
    },

    async delete(id) {
        const response = await httpService.delete('fee', id);
        if (!response.status) {
            response.data = false;
        }
        return response;
    },

    default() {
        return {
            id: 0,
            remarks: "",
            validStart: null,
            validEnd: null,
            quantity: 0,
            status: 0,
            feeProfileListId: 0,
            feeId: 0,
            feeName: "",
            feeProfileId: 0,
            feeProfileNama: "",
            inputFees: [],
        };
    },

    _toObject(jsonData) {
        const result = this.default();
        result.id = jsonData.id;
        result.remarks = jsonData.remarks;
        result.validStart = jsonData.validStart;
        result.validEnd = jsonData.validEnd;
        result.quantity = jsonData.quantity;
        result.status = jsonData.status;
        result.feeProfileListId = jsonData.feeProfileListId;
        result.feeId = jsonData.feeId;
        result.feeName = jsonData.feeName;
        result.feeProfileId = jsonData.feeProfileId;
        result.feeProfileNama = jsonData.feeProfileNama;
        result.inputFees = jsonData.inputFees? [...jsonData.inputFees] : [];
        return result;
    }
});