<template>
	<bs-select v-model="text" @onSelect="selectProfile" @onSearch="filterUnit" :options="list">
		<template v-slot:default="props">
			<div>{{props.item.nama}}</div>
		</template>
	</bs-select>
</template>
<script>
import FeeProfileService from "@/services/fee-profile-service";

export default {
	name: 'FeeProfileCombo',
	data: function() {
		return {
			text: '',
			list: null,
			hasFocus: false
		}
	},
	props: {
		modelValue: Number,
		textDefault: String
	},
	updated() {
		if (!this.hasFocus)
		{
			this.hasFocus = true;
			this.setComboLabel(this.modelValue);
		}
		},
        emits: ['update:modelValue', 'setSelectedProfile'],
	methods: {
		async setComboLabel(id) {
			if (id === undefined || id === null || id === '' || id < 1) {
				this.text = '';
				return;
			}

			const obj = await FeeProfileService.getObject(id);
			if (obj.status) {
				this.text = `${obj.data.nama}`;
			}
			else {
				this.text = '';
				alert(`Failed load profile with id = '${id}'.`);
			}
		},
		selectProfile(item) {
			if (item == null) {
				this.$emit('update:modelValue', null);
			}
			else
			{
				this.$emit('update:modelValue', item.id);
				this.$emit('setSelectedProfile', item.startDay, item.timeRangeNama, item.feeProfileList);
			}
			this.setComboLabel(item == null ? null : item.id);
		},
		async filterUnit(value) {
			const filter = {
				namaContains: value
			};

			const result = await FeeProfileService.getList(filter);
			let arrayResult = null;
			if (result.status) {
				if (result.data != null && result.data.data != null)
				{
					arrayResult = result.data.data;
				}
			}

			this.list = arrayResult;
		}
	}}
</script>