import Moment from "moment";
import {OPSI_BULAN} from "@/plugins/constant";

export function getMonthDateRange(year, month) {

    // month in moment is 0 based, so 9 is actually october, subtract 1 to compensate
    // array is 'year', 'month', 'day', etc
    var startDate = Moment([year, month - 1]);

    // Clone the value before .endOf()
    var endDate = Moment(startDate).endOf('month');

    // make sure to call toDate() for plain JavaScript date type
    return { start: startDate, end: endDate };
}

function endFirstWeek(firstDate, firstDay) {
    if (! firstDay) {
        return 7 - firstDate.getDay();
    }
    if (firstDate.getDay() < firstDay) {
        return firstDay - firstDate.getDay();
    } else {
        return 7 - firstDate.getDay() + firstDay;
    }
}

export function getWeeksStartAndEndInMonth(month, year) {
    let weeks = [],
        firstDate = new Date(year, month, 1),
        lastDate = new Date(year, month + 1, 0),
        numDays = lastDate.getDate();

    let start = 1;
    let end = endFirstWeek(firstDate, 2);
    while (start <= numDays) {
        weeks.push({start: start, end: end});
        start = end + 1;
        end = end + 7;
        end = start === 1 && end === 8 ? 1 : end;
        if (end > numDays) {
            end = numDays;
        }
    }
    return weeks;
}

export function getWeeksInMonth(year, month) {
    const weeks = []

    let firstDate = new Date(year, month, 1)
    let lastDate = new Date(year, month + 1, 0)
    let numDays = lastDate.getDate()

    let dayOfWeekCounter = firstDate.getDay()

    for (let date = 1; date <= numDays; date++) {
        if (dayOfWeekCounter === 0 || weeks.length === 0) {
            weeks.push([])
        }
        weeks[weeks.length - 1].push(date)
        dayOfWeekCounter = (dayOfWeekCounter + 1) % 7
    }

    /* This is to add the last week of the previous month to the first week of the current month. */
    if (weeks[0].length < 7) {
        const beforeIndex1 = addMonth(year, month - 1, 1)
        const indexRefactor = [...beforeIndex1, ...weeks[0]]
        weeks[0] = indexRefactor
    }

    /* This is to add the first week of the next month to the last week of the current month. */
    if (weeks[weeks.length - 1].length < 7) {
        const afterIndex1 = addMonth(year, month + 1, 0)
        const indexRefactor = [...weeks[weeks.length - 1], ...afterIndex1]
        weeks[weeks.length - 1] = indexRefactor
    }

    return weeks
        .filter((w) => !!w.length)
        .map((w) => ({
            start: w[0],
            end: w[w.length - 1],
            dates: w,
        }))
}

export const addMonth = (year, month, flag) => {
    const weeks = [],
        firstDate = new Date(year, month, 1),
        lastDate = new Date(year, month + 1, 0),
        numDays = lastDate.getDate()

    let dayOfWeekCounter = firstDate.getDay()
    for (let date = 1; date <= numDays; date++) {
        if (dayOfWeekCounter === 0 || weeks.length === 0) {
            weeks.push([])
        }
        weeks[weeks.length - 1].push(date)
        dayOfWeekCounter = (dayOfWeekCounter + 1) % 7
    }
    if (flag === 0) {
        return weeks[0]
    }
    if (flag === 1) {
        return weeks[weeks.length - 1]
    }
    return []
}

export function getCurrentYear() {

    return Moment().year();
}

export function getFirstGivenIndexDate(year, month, indexDate) {
    // Create a Moment.js object for the 1st day of the specified month and year
    const currentIndexDate = indexDate === 7 ? 0 : indexDate
    var startDate = Moment([year, month - 1]);

    while (startDate.day() !== currentIndexDate ) {
        startDate.add(1, 'day'); // Move to the next day
    }

    return startDate;
}

export function getNamaBulan(index){
    if (index){
        return OPSI_BULAN.filter((item)=>item.id === index)[0].label
    }
    return ''
}

